
import TabLayout from "@/components/TabLayout.vue";
import ColorScheme from "./GeneralInterface.vue";
import CustomCSS from "./custom-css/CustomCSS.vue";

import { defineComponent } from "vue";
export default defineComponent({
  name: "Interface",
  components: { TabLayout },
  computed: {
    tabs(): any {
      const tabs: any = [
        {
          name: this.$t("settings.interface.general"),
          id: "color_scheme",
          component: ColorScheme,
        },
        {
          name: this.$t("settings.interface.custom-css"),
          id: "custom_css",
          component: CustomCSS,
        },
      ];

      return tabs;
    },
  },
});
