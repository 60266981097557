
import { Theme } from "@/services/themeService";
import ThemeList from "./ThemeList.vue";
import { defineAsyncComponent } from "vue";

const Editor = defineAsyncComponent(() => import("./Editor.vue"));
const PublishOptions = defineAsyncComponent(
  () => import("./PublishOptions.vue")
);
import { defineComponent } from "vue";
export default defineComponent({
  name: "CustomCSS",
  components: { ThemeList, Editor, PublishOptions },
  data() {
    return {
      editID: null as null | string,
      publishTheme: null as null | Theme,
    };
  },
});
