
import { ThemePreview } from "@/services/themeService";
import CustomButton from "@/components/CustomButton.vue";
import { applyTheme, unapplyTheme } from "@/utils/CSSTheme";
import { PropType } from "vue";
import { defineComponent } from "vue";
export default defineComponent({
  name: "ThemeTemplate",
  components: { CustomButton },
  props: {
    theme: {
      type: Object as PropType<ThemePreview>,
      required: true,
    },
    showOptions: {
      type: Boolean,
      default: false,
    },
    applied: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    apply() {
      applyTheme(this.theme.id);
      this.$emit("applied");
    },
    unapply() {
      unapplyTheme();
      this.$emit("unapplied");
    },
  },
});
